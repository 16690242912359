<template>
  <div class="loading">
    <div class="load-container load6">
      <div class="loader"></div>
     </div>
  </div>
</template>

<script>
export default {
  name: "HomeLoading"
}
</script>

<style scoped>
.loading {
  position: fixed;
  left: 0;
  top: 0;
  /*background: rgba(247, 1, 50, 1);*/
  background: #F88F00;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
}

.load6 .loader {
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 0.8em auto;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%,-50%);
  -webkit-animation: load6 1.7s infinite ease;
  animation: load6 1.7s infinite ease;

}
@-webkit-keyframes load6 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    box-shadow: -0.11em -0.83em 0 -0.4em #ff0000,
    -0.11em -0.83em 0 -0.42em #ff0000,
    -0.11em -0.83em 0 -0.44em #ff0000,
    -0.11em -0.83em 0 -0.46em #ff0000,
    -0.11em -0.83em 0 -0.477em #ff0000;
  }
  5%,
  95% {
    box-shadow: -0.11em -0.83em 0 -0.4em #ff0000,
    -0.11em -0.83em 0 -0.42em #ff0000,
    -0.11em -0.83em 0 -0.44em #ff0000,
    -0.11em -0.83em 0 -0.46em #ff0000,
    -0.11em -0.83em 0 -0.477em #ff0000;
  }
  30% {
    box-shadow: -0.11em -0.83em 0 -0.4em #ff0000,
    -0.51em -0.66em 0 -0.42em #ff0000,
    -0.75em -0.36em 0 -0.44em #ff0000,
    -0.83em -0.03em 0 -0.46em #ff0000,
    -0.81em 0.21em 0 -0.477em #ff0000;
  }
  55% {
    box-shadow: -0.11em -0.83em 0 -0.4em #ff0000,
    -0.29em -0.78em 0 -0.42em #ff0000,
    -0.43em -0.72em 0 -0.44em #ff0000,
    -0.52em -0.65em 0 -0.46em #ff0000,
    -0.57em -0.61em 0 -0.477em #ff0000;
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    box-shadow: -0.11em -0.83em 0 -0.4em #ff0000,
    -0.11em -0.83em 0 -0.42em #ff0000,
    -0.11em -0.83em 0 -0.44em #ff0000,
    -0.11em -0.83em 0 -0.46em #ff0000,
    -0.11em -0.83em 0 -0.477em #ff0000;
  }
}
@keyframes load6 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    box-shadow: -0.11em -0.83em 0 -0.4em #ff0000,
    -0.11em -0.83em 0 -0.42em #ff0000,
    -0.11em -0.83em 0 -0.44em #ff0000,
    -0.11em -0.83em 0 -0.46em #ff0000,
    -0.11em -0.83em 0 -0.477em #ff0000;
  }
  5%,
  95% {
    box-shadow: -0.11em -0.83em 0 -0.4em #ff0000,
    -0.11em -0.83em 0 -0.42em #ff0000,
    -0.11em -0.83em 0 -0.44em #ff0000,
    -0.11em -0.83em 0 -0.46em #ff0000,
    -0.11em -0.83em 0 -0.477em #ff0000;
  }
  30% {
    box-shadow: -0.11em -0.83em 0 -0.4em #ff0000,
    -0.51em -0.66em 0 -0.42em #ff0000,
    -0.75em -0.36em 0 -0.44em #ff0000,
    -0.83em -0.03em 0 -0.46em #ff0000,
    -0.81em 0.21em 0 -0.477em #ff0000;
  }
  55% {
    box-shadow: -0.11em -0.83em 0 -0.4em #ff0000,
    -0.29em -0.78em 0 -0.42em #ff0000,
    -0.43em -0.72em 0 -0.44em #ff0000,
    -0.52em -0.65em 0 -0.46em #ff0000,
    -0.57em -0.61em 0 -0.477em #ff0000;
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    box-shadow: -0.11em -0.83em 0 -0.4em #ff0000,
    -0.11em -0.83em 0 -0.42em #ff0000,
    -0.11em -0.83em 0 -0.44em #ff0000,
    -0.11em -0.83em 0 -0.46em #ff0000,
    -0.11em -0.83em 0 -0.477em #ff0000;
  }
}



@media only screen and (min-width:0px) and (max-width:767px) {
  .load6 .loader {

    top: 30%;
    left: 40%;
    transform: translate(-30%,-40%);

  }

}


</style>