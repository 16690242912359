<template>
<div class="home-news">
 <div class="container">

     <div class="common-title home-news-title">
       <div class="common-title-top">
           <span>
             <i class="layui-icon layui-icon-rate-solid"></i>
           </span>
         <span class="common-line"></span>
       </div>
       <h3>最新消息</h3>
     </div>


  <div class="home-news-tab">
    <TabControl  class="tab-control"
                 :titles="['活動推介', '活動回顧', '其他資訊']"
                 @tabClick="tabClick"></TabControl>
  </div>

  <news-swiper :news="showNews"></news-swiper>


 </div>
</div>



</template>

<script>
import TabControl from "@/components/TabControl";
import newsSwiper from "@/components/content/newsSwiper";

import {getIndexInfo} from "@/network/home";

export default {
  name: "homeNews",
  components: {
    TabControl,
    newsSwiper
  },
  data() {
    return {
      news: {
        '1': {list: []},
        '2': {list: []},
        '3': {list: []},
      },
      currentType: '1'
    }
  },
  computed: { //计算属性
    showNews() {
      return this.news[this.currentType].list
    }
  },
  created() {
    // this.getIndexInfo();
    this.getIndexInfo('1');
    this.getIndexInfo('2');
    this.getIndexInfo('3');
  },
  methods: {
     tabClick(index) {
      console.log(index)
      switch (index) {
        case 0:  //如果当前的index是0,默认展示1
          this.currentType = '1'
          break
        case 1: //如果是1, 展示new
          this.currentType = '2'
          break
        case 2:
          this.currentType = '3'
          break
      }
    },

    getIndexInfo(type) {
      getIndexInfo(type).then(res => {
        //console.log(res.data.data.news)
        this.news[type].list.push(...res.data.data.news[type]) //拿到对应type的数据
       })
    }
 },

}
</script>

<style scoped>
.home-news{
  padding: 50px 0;
  background: #F6F5F5 url("~@/assets/img/home_bg_news.png") top center no-repeat;
}
.home-news-title{
  color: #fff;
}

.home-news-title i {
  color: #fff;
}
.home-news-title .common-line{
  background: #fff;
}
.home-news-tab{
   padding-top: 50px;
   padding-right: 30%;
}

@media only screen and (min-width: 0px) and (max-width: 991px) {
  .home-news-tab {
    padding-top: 20px;
    padding-right: 0;
  }
 .home-news {
    padding: 20px 0;
  }
}





</style>