<template id="tem">

 <div>
    <transition name="fade">
      <homeLoading v-if="isLoading"></homeLoading>
    </transition>
  </div>

  <div class="home">
    <home-banner :banner="banner" :setting="setting"></home-banner>
    <home-service :setting="setting"/>
    <home-news/>
    <home-video :video="video"/>
  </div>

</template>

<script>
import homeLoading from "@/views/homeDetail/HomeLoading";
import homeBanner from "@/views/homeDetail/homeBanner";
import homeService from "@/views/homeDetail/homeService";
import homeNews from "@/views/homeDetail/homeNews";
import homeVideo from "@/views/homeDetail/homeVideo";

import {getIndexInfo} from "@/network/home";

export default {
  name: 'Home',
  components: {
    homeBanner,
    homeService,
    homeNews,
    homeVideo,
    homeLoading
 },
  data() {
     return {
       isLoading: false,
       banner: [],
       setting: {},
       video: [],
    }
  },
  created() {
    this.getIndexInfo();
    //添加灰色---进入首页
    // document.querySelector("html").setAttribute("style","filter: grayscale(1)")

    },



  methods: {

    getIndexInfo() {
      this.isLoading = true;
      getIndexInfo().then(res =>{
        //banner
        this.banner = res.data.data.banner;
       //txt
        this.setting = res.data.data.setting;
        //video
        this.video = res.data.data.channels;

        this.isLoading = false;

      })
    },

    mounted() {
      this.isLoading = false;
    },


},

 //移除灰色---退出首页
 // beforeUnmount() {
 //    document.querySelector('html').removeAttribute('style')
 //  },

}
</script>

<style scoped>

</style>