<template>


    <div class="news-item"  @click="btnClick" style="cursor: pointer">
    <div class="news-item-img">
      <div class="news-data">
         <p>{{ newsItem.year }}年{{ newsItem.month }}月{{ newsItem.day }}日</p>
      </div>
      <img :src="newsItem.image" style="max-width: 100%">
    </div>
    <div class="news-item-txt">
      <h4>{{newsItem.title}}</h4>
      <p>{{newsItem.info}}</p>
       <span>
        查看更多 <i class="layui-icon layui-icon-right"></i>
       </span>
    </div>
    </div>


</template>

<script>
export default {
  name: "newsItem",
  props: {
    newsItem: {
      type:Object, //返回对象
      default() {
        return {}
      }
    }
  },
  methods:{
    btnClick() {
      this.$router.push('/newsDetail/' + this.newsItem.id)//传递id
    }
  },

}
</script>

<style scoped>
.news-item{
  width: 100%;
}


.news-item{
  border-radius: 4px;
  border: 1px solid #CED3D6;
  padding: 10px;
  background: transparent;

}



.news-data{
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  padding: 15px;
}

.news-data p{
  text-align: center;
  color: #DE002B;
}
.news-data p b{
  font-size: 18px;
}

.news-item-img{
  position:relative;
}

.news-item-img img{
  width: 100%;
}

.news-item-txt{
  background: #fff;
  padding: 10px;
}
.news-item h4{
  font-size: 15px;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.news-item p{
  overflow:hidden;
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:2;
}

.news-item a{
  border-top:1px solid #CED3D6;
  padding:10px 0;
  color: #002776;
  display: block;
  margin-top: 20px;
}


.compo-news-item{
  width: 33%;
}
.compo-news-item{
 padding: 10px 15px;
}

.news-item-txt span{
  margin-top: 20px;
  padding: 10px 0;
  display: block;
  border-top:1px solid #ddd;
}



@media only screen and (min-width:0px) and (max-width:767px) {

}





</style>