<template>
<div class="home-video">
  <div class="container">
    <div class="common-title home-video-title">
      <router-link to="/channel">
      <div class="common-title-top">
           <span>
             <i class="layui-icon layui-icon-rate-solid"></i>
           </span>
        <span class="common-line"></span>
      </div>
      <h3 style="color:#DE002B ">康齡頻道</h3>
      </router-link>
    </div>
   <div class="home-video-list">
     <ul>
       <li v-for="item in video" :key="item">
         <iframe v-bind:src="item.video_link" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
         <p>{{item.name}}</p>
       </li>
      </ul>
   </div>
  </div>
</div>
</template>

<script>
export default {
  name: "homeVideo",
  props: {
    video: {
      Array,
      default() {
        return [];
      }
    }
  }
}
</script>

<style>
.home-video{
  padding: 50px 0;
  background: #fff url("~@/assets/img/home_bg_video.png") bottom center no-repeat;
}

.home-video-title, .home-video-title i{
  color: #DE002B;
}

.home-video-title .common-line{
  background:#DE002B;
}

.home-video-list{
  padding-top: 50px;
}

.home-video-list ul{
  display: flex;
  flex-wrap: wrap;
}

.home-video-list ul li{
  flex: 0 0 33.33%;
  max-width: 100%;
  padding: 20px 10px;
}

.home-video-list ul li iframe{
  width: 100%;
  height: 300px;
}


.home-video-list ul li p{
  font-size: 16px;
}

@media only screen and (min-width:0px) and (max-width:991px) {

  .home-video{
    padding: 20px 0;
  }

  .home-video-list{
    padding-top: 20px;
  }
  .home-video-list ul li{
    flex: 0 0 100%;
    padding: 10px 0;
 }

}


</style>