<template>
  <div class="header">
    <div class="container">
      <div class="top">
        <div class="logo">
          <router-link to="/">
            <img src="@/assets/img/logo@2x.png">
          </router-link>
        </div>
        <div class="nav">

          <div class="index-nav">
            <div class="index-nav-frame clearfix">
             <div class="nav-small" tabindex="-1" v-on:click="show = !show">
                <div class="nav-small-focus" tabindex="-1">
                </div>
                <img src="@/assets/img/menu.svg" />
              </div>
              <div v-if="show">
                <div class="index-nav-frame-line active" tabindex="-1" @click="mobileClick">
                  <router-link to="/about" :class="{'active': $route.path === '/about'}">關於我們</router-link>
                </div>
                <div class="index-nav-frame-line" tabindex="-1">

                  <div v-if="isShow"  @click="mobileClick">
                     <router-link to="/elder" :class="{'active': $route.path === '/elder'}">
                       服務範疇<i class="layui-icon layui-icon-down nav-triangle-s"></i>
                     </router-link>
                   </div>
                    <div v-else>
                      服務範疇<i class="layui-icon layui-icon-down nav-triangle-s"></i>
                   </div>

                  <div class="index-nav-frame-line-center" ref="one">
                    <div class="index-nav-frame-line-li" @click="mobileClick">
                      <router-link to="/elder">長者服務 <i class="layui-icon layui-icon-down nav-triangle-s"></i></router-link>
                        <div class="sub">
                           <ul>
                             <li>
                               <router-link to="/serviceMore/1"><span></span>土瓜灣中心</router-link>
                             </li>
                             <li>
                               <router-link to="/serviceMore/4"><span></span>翠屏中心</router-link>
                             </li>

                           </ul>
                       </div>
                    </div>
                    <div class="index-nav-frame-line-li" @click="mobileClick">
                      <router-link to="/volunteer">義工服務</router-link>
                    </div>
                    <div class="index-nav-frame-line-li">
                      <router-link to="/educate">教育服務</router-link>
                    </div>
                  </div>
                  <div class="index-nav-frame-line-focus" tabindex="-1"></div>
                </div>
                <div class="index-nav-frame-line" tabindex="-1">

                  <div v-if="isShow"  @click="mobileClick">
                    <router-link to="/recommend" :class="{'active': $route.path === '/recommend'}">
                      最新消息<i class="layui-icon layui-icon-down nav-triangle-s"></i>
                    </router-link>
                  </div>
                  <div v-else>
                    最新消息<i class="layui-icon layui-icon-down nav-triangle-s"></i>
                  </div>

                  <div class="index-nav-frame-line-center" ref="two">
                    <div class="index-nav-frame-line-li" @click="mobileClick">
                      <router-link to="/recommend">活動推介</router-link>
                    </div>
                    <div class="index-nav-frame-line-li" @click="mobileClick">
                      <router-link to="/activityReview">活動回顧</router-link>
                    </div>
                    <div class="index-nav-frame-line-li" @click="mobileClick">
                      <router-link to="/other">其他資訊</router-link>
                    </div>
                  </div>
                  <div class="index-nav-frame-line-focus" tabindex="-1"></div>
                </div>
                <div class="index-nav-frame-line" tabindex="-1" >

                  <div v-if="isShow"  @click="mobileClick">
                    <router-link to="/administration" :class="{'active': $route.path === '/administration'}">
                      機構刊物<i class="layui-icon layui-icon-down nav-triangle-s"></i>
                    </router-link>
                  </div>
                  <div v-else>
                    機構刊物<i class="layui-icon layui-icon-down nav-triangle-s"></i>
                  </div>

                  <div class="index-nav-frame-line-center" ref="three">
                    <div class="index-nav-frame-line-li" @click="mobileClick">
                      <router-link to="/administration">行政中心 <i class="layui-icon layui-icon-down nav-triangle-s"></i></router-link>
                      <div class="sub">
                        <ul>
                          <li v-for="item in one" :key="item" @click="adminCenter(item.id)" :id="item.id">
                            {{item.title}}
                          </li>
                         </ul>
                      </div>
                    </div>
                    <div class="index-nav-frame-line-li" @click="mobileClick">
                      <router-link to="/tokwawan">土瓜灣中心 <i class="layui-icon layui-icon-down nav-triangle-s"></i></router-link>
                      <div class="sub">
                        <ul>
                          <li v-for="item in two" :key="item" @click="tkw(item.id)"  :id="item.id">
                          {{item.title}}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="index-nav-frame-line-li" @click="mobileClick">
                      <router-link to="/cuiping">翠屏中心 <i class="layui-icon layui-icon-down nav-triangle-s"></i></router-link>
                      <div class="sub">
                        <ul>
                          <li v-for="item in three" :key="item" @click="cp(item.id)"  :id="item.id">
                              {{item.title}}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="index-nav-frame-line-focus" tabindex="-1"></div>
                </div>
                <div class="index-nav-frame-line" tabindex="-1" @click="mobileClick">
                  <router-link to="/donate" :class="{'active': $route.path === '/donate'}">捐款</router-link>
                </div>
                <div class="index-nav-frame-line" tabindex="-1"  @click="mobileClick">
                  <router-link to="/channel" :class="{'active': $route.path === '/channel'}">康齡頻道</router-link>
                </div>
                <div class="index-nav-frame-line last-index-nax" tabindex="-1" @click="mobileClick">
                  <router-link to="/contact" :class="{'active': $route.path === '/contact'}">聯絡我們</router-link>
                </div>
              </div>

            </div>
          </div>

        </div>

      </div>
    </div>
 </div>
</template>

<script>

import {getContactInfo} from "@/network/home";

export default {
  name: "Header",
  data() {
    return {
      type: "/",
      navItem: [
        {'navItemName': '本社簡介', 'navItemUrl': '/about'},
        {'navItemName': '服務範圍', 'navItemUrl': '/service'},
        {'navItemName': '最新消息', 'navItemUrl': '/newsList'},
        {'navItemName': '機構刊物', 'navItemUrl': '/periodical'},
        {'navItemName': '捐款', 'navItemUrl': '/donate'},
        {'navItemName': '康齡頻道', 'navItemUrl': '/channel'},
        {'navItemName': '聯絡我們', 'navItemUrl': '/contact'},
      ],
      show: false,
      isShow:true,
      one:[],
      two:[],
      three:[],
    }
  },

  methods: {
    goHere(item) {
      this.type = item;
      switch (item) {
        case "/":
          this.$router.push({ path: "/" });
          break;

        case "/about":
          this.$router.push({ path: "/about" });
          break;

        case "/service":
          this.$router.push({ path: "/service" });
          break;

        case "/newsList":
          this.$router.push({ path: "/newsList" });
          break;

        case "/periodical":
          this.$router.push({ path: "/periodical" });
          break;

        case "/donate":
          this.$router.push({ path: "/donate" });
          break;

        case "/channel":
          this.$router.push({ path: "/channel" });
          break;

        case "/contact":
          this.$router.push({ path: "/contact" });
          break;
      }
    },
    mobileClick() {  //手机端导航点击后默认不显示下拉
      if(window.innerWidth < 767){
        this.show= false;
      }
    },

    getContactInfo(){
      getContactInfo().then(res =>{//.then可以拿到数据
        // console.log(res.data.data)
        this.one = res.data.data.publication_categories.one;
        this.two = res.data.data.publication_categories.two;
        this.three = res.data.data.publication_categories.three;
      })
    },


    adminCenter(id){
      this.$router.push('/administration/' + id)//传递id
    },
    tkw(id){
      this.$router.push('/tokwawan/' + id)//传递id
    },
    cp(id){
      this.$router.push('/cuiping/' + id)//传递id
    },

  },
 created() {

    this.getContactInfo()

    //判断屏幕宽度,是否默认显示下拉
    if(window.innerWidth >= 768){
        this.show= true;
    }else{
      this.isShow= false;
   }

  },

  mounted () {

     // 判断宽度为iPad区域
    if(window.innerWidth >= 768 && window.innerWidth <= 1024){
      //修改固定类的样式,给div加ref,ref具有唯一性
      this.$refs.one.style = 'display:none';
      this.$refs.two.style = 'display:none';
      this.$refs.three.style = 'display:none';
    }else{
      console.log('not ipad')
    }
 },

}



</script>

<style scoped>
.header{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 92px;
  background-color: #fff;
  z-index: 9;
}

.top{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}
.logo img{
  height: 72px;
}

.active{
  color:#b63b4d;
}


@media only screen and (min-width:768px) and (max-width:991px) {
  .logo img{
    height: 48px;
  }
  .header{
    height: 71px;
   }

}


@media only screen and (min-width:0px) and (max-width:767px) {
.top{
  flex-wrap: wrap;
}
  .logo{
    padding-left: 10px;
  }
   .logo img{
     height: 48px;
 }
   /*.logo{*/
   /*  display: block;*/
   /*  margin: auto;*/
   /*}*/
  .header{height: 68px;}

}

/******/
.clearfix:after{visibility:hidden;display: block;font-size:0;content:" ";clear:both;height:0;}
* html .clearfix{ zoom: 1; } /* IE6 */
*:first-child+html .clearfix { zoom: 1; } /* IE7 */
.nav-triangle-s{ font-size: 12px;margin-left: 3px}
.index-nav{height: 50px;box-sizing: border-box;background:white;}

.index-nav-frame-line{color: #333333;
  float: left;position: relative;
  display: block;outline: none;
  cursor: pointer;line-height: 50px;text-align: left;font-weight: 700;
  padding: 0 22px;
}
.last-index-nax{
  padding: 0 0 0 22px;}

.index-nav-frame-line.active{color:#b63b4d;}

.index-nav-frame-line-center{opacity: 0;height: 0;position: absolute;overflow: hidden;width: 100%;transition: all 0.5s;
  -webkit-transition: all 0.5s;-moz-transition: all 0.5s;/* Firefox 4 */-o-transition: all 0.5s; /* Opera */}
.index-nav-frame-line-li{width: 100%;font-weight: 500;background: white;color: #666666;
line-height: 30px;padding: 10px; border-bottom: 1px solid #ddd}

.index-nav-frame-line-li:hover{background: #332d30;color: white;}
.index-nav-frame-line-li:hover a{background: #332d30;color: white;}
.index-nav-frame-line-li:hover a span{background: #fff;}


.index-nav-frame-line-focus:focus{display: none;}
.index-nav-frame-line:hover .index-nav-frame-line-center{height:auto;opacity: 1;}

.nav-small{width: 36px;height: 36px;position: absolute;right: 10px;top:20px;cursor: pointer;display: none;outline: none;}
.nav-small img{width: 100%;height: 100%;object-fit: cover;}
.nav-small-focus{position: absolute;width: 100%;height: 100%;display: none;}
.nav-small-focus:focus{display: none;}


/*submenu*/


.sub ul li {
  line-height: 20px;
  text-align: left;
  padding: 5px 0;
}
.sub ul li{
  display: flex;

  align-items: center;
  font-size: 15px;

 }

 .sub ul li span{
  width: 3px;
  height: 3px;
  background: #000;
  border-radius: 100%;
   margin-right: 5px;
}

.sub ul li a:hover,
.sub ul li:hover{
  opacity: .7;
}


@media only screen and (max-width:1120px) {
  .index-nav-frame-line{
    padding: 0 10px;
    font-size: 14px;
  }
  .last-index-nax{
    padding: 0 0 0 10px;}
}
@media only screen and (max-width:991px) {
  .index-nav-frame-line{
    padding: 0 5px;
  }
  .last-index-nax{
    padding: 0 0 0 5px;}
}
@media only screen and (max-width:767px) {

  .index-nav-frame-line{
    line-height: 44px;
  }
  .index-nav-frame-line-li a{display: block;color: white;}
  .nav-line{display: block;border-bottom: 1px solid #ddd;}
  .nav-small{display: block;}
  .nav-small:focus~.index-nav-frame-line{height: auto;border-bottom: 1px solid #000;}
  .nav-small:focus .nav-small-focus{display: block;}
  .index-nav-frame{width: 100%;}
  .index-nav-frame-line{width: 100%;
    /*height: 0;*/
    overflow: hidden;}
  .index-nav-frame-line-center{position: relative;background: #444359;}
  /*.index-nav-frame-line:hover .index-nav-frame-line-center{height:0;opacity: 0;}*/
  .index-nav-frame-line-li{border-bottom: 1px solid #4b4a5e;
    color: #d9d9d9;background: #332d30;}
  .index-nav-frame-line-li:hover{background: #332d30;}
  .index-nav-frame-line:focus{height: auto;border-bottom: 1px solid #ccc;}
  .index-nav-frame-line:focus>.index-nav-frame-line-center{height:auto;opacity: 1;}
  .index-nav-frame-line:focus .index-nav-frame-line-header{color: #B63B4D;}
  .index-nav-frame-line-focus{display: none;width: 100%;height: 50px;position: absolute;left: 0;top: 0;}
  .index-nav-frame-line:focus .index-nav-frame-line-focus{display: block;}
  .index-nav{
    height: 0;
  }
  .index-nav-frame-line{
    background: #fff;

  }
.header .container{
  padding: 0;
}

.index-nav-frame-line a{
  display:block;
  padding: 3px 0;
}


  .sub ul li a span{

    background: #fff;

  }


}




</style>