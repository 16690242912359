import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'

const routes = [
  {
    path: '/',
    name: '首页',
    component: Home
  },
  {
    path: '/about',
    name: '关于本社',
    component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue')
  },

  {
    path: '/elder',
    name: '安老服務',
    component: () => import('@/views/serviceDetail/Elder.vue'),
  },
  {
    path: '/volunteer',
    name: '義工服務',
    component: () => import('@/views/serviceDetail/Volunteer.vue'),
  },
  {
    path: '/educate',
    name: '教育服務',
    component: () => import('@/views/serviceDetail/Educate.vue'),
  },
  {
    path: '/serviceMore/:id',
    name: '服务详情页',
    component: () => import('@/views/serviceDetail/serviceMore.vue')
  },
  {
    path: '/review/:id',
    name: '活动回顾详情页',
    component: () => import('@/views/serviceDetail/Review.vue')
  },


  {
    path: '/donate',
    name: '捐款',
    component: () => import('@/views/Donate.vue')
  },
  {
    path: '/channel',
    name: '康龄频道',
    component: () => import('@/views/Channel.vue')
  },
  {
    path: '/contact',
    name: '联系我们',
    component: () => import('@/views/Contact.vue')
  },
  {
    path: '/tableDownload',
    name: '表格下载',
    component: () => import('@/views/tableDownload.vue')
  },

  {
    path: '/recommend',
    name: '活动推荐',
    meta:{ keepalive: true },
    component: () => import('@/views/newsDetail/Recommend.vue')
  },
  {
    path: '/activityReview',
    name: '活动回顾',
    meta:{ keepalive: true },
    component: () => import('@/views/newsDetail/activityReview.vue')
  },
  {
    path: '/other',
    name: '其他活动',
    meta:{ keepalive: true },
    component: () => import('@/views/newsDetail/Other.vue')
  },
 {
    path: '/newsDetail/:id',
    name: '消息详情页',
    component: () => import('@/views/newsDetail/newsDetail.vue'),
 },
 {
    path: '/administration/:id',
    name: '行政中心',
    component: () => import('@/views/periodDetail/administration.vue')
  },
  {
    path: '/tokwawan/:id',
    name: '土瓜灣',
    component: () => import('@/views/periodDetail/ToKwaWan.vue')
  },
  {
    path: '/cuiping/:id',
    name: '翠屏中心',
    component: () => import('@/views/periodDetail/Cuiping.vue')
  },



 //无年份分类
  {
    path: '/administration',
    name: 'administration',
    component: () => import('@/views/periodDetail/period1.vue')
  },
  {
    path: '/tokwawan',
    name: 'tokwawan',
    component: () => import('@/views/periodDetail/period2.vue')
  },
  {
    path: '/cuiping',
    name: 'cuiping',
    component: () => import('@/views/periodDetail/period3.vue')
  },


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  //scrollBehavior滚动行为
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 } //期望滚动到哪个的位置
    }
  },
})


//路由点击跳转后页面回到顶部
router.afterEach((to, form, next) => {
  window.scrollTo(0, 0)
  next()
})

export default router
