<template>
  <div class="home-b-content">



<!--banner-->
   <div class="home-banner">
      <swiper
          :pagination="{
           clickable: true,
            }"
          :autoplay="{
            delay:6000,
            disableOnInteraction: false,
           }"
          :modules="modules" class="mySwiper">

          <swiper-slide v-for="item in banner" :key="item">
            <a :href="item.link" target="_blank">
              <img :src="item.image">
            </a>
          </swiper-slide>

      </swiper>
    </div>

<!--icon-->
    <div class="home-service">
      <div class="container home-service-content">
        <div class="home-service-item">
          <div class="service-item">
            <router-link to="/elder">
              <img src="@/assets/img/icon_laoren.png">
              <p>長者服務</p>
            </router-link>
          </div>
          <div class="service-item">
            <router-link to="/volunteer">
              <img src="@/assets/img/icon_yigong.png">
              <p>義工服務</p>
            </router-link>
          </div>
          <div class="service-item">
            <router-link to="/educate">
              <img src="@/assets/img/icon_book.png">
              <p>教育服務</p>
            </router-link>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>
<script>


// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';

// Import Swiper styles
import 'swiper/swiper-bundle.css'
// import required modules
import { Pagination,Autoplay  } from "swiper";


export default {
  name: "homeBanner",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    banner: {
      type: Array, //返回数组
      default() {
        return []
      }
    },
    setting: {
      type:Object, //返回对象
      default() {
        return {}
      }
    }
  },
  setup() {
    return {
       modules: [Pagination, Autoplay],
    };
  },
}

</script>


<style>
.home-b-content{
  padding-bottom: 60px;
  position: relative;
  background: #F6F5F5;
}


.banner-container img{
  max-width:100%;
  display: block;
  margin: auto;
}


.banner-txt h1{
  font-size: 40px;
  font-weight: bold;
}

.banner-txt p{
  padding: 20px 0;
  font-size: 16px;
}

.banner-txt a{
  padding: 15px 30px;
  background: #F88F00;
  border-radius: 4px;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  display: inline-block;
}


.home-service{
  position: absolute;
  bottom: 0;
  left:0;
  right: 0;
  z-index: 1;
}

.home-banner{
  padding-top: 92px;
  width: 100%;
  overflow: hidden;
}


.swiper-pagination-fraction, .swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 160px;

}

.swiper-pagination-bullet {
  width: 16px;
  height: 16px;

  background: #fff;
  opacity: 0.6;
}


.swiper-pagination-bullet-active {
  opacity: 1;
  background: #DE002B;
}

.home-service-item{
  display: flex;
  height: 183px;
  box-shadow: 0px 10px 10px 0px #d1d1d114;
}
.service-item{
   flex: 1;
   background: url("~@/assets/img/home_service_card_bg.png") top center no-repeat;
}


.service-item{
 padding-top: 55px;

}
.service-item img{
  display: block;
  margin: auto;
  transition: -webkit-transform .5s cubic-bezier(0, 0, .44, 1.18);
  transition: transform .5s cubic-bezier(0, 0, .44, 1.18);
  transition: transform .5s cubic-bezier(0, 0, .44, 1.18), -webkit-transform .5s cubic-bezier(0, 0, .44, 1.18);

}

.service-item:hover img{
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}


.service-item p{
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  color: #000;
  padding-top: 10px;
}

@media only screen and (min-width:992px) and (max-width:1100px) {

  .banner-txt h1{
    font-size: 30px;
  }

  .banner-txt a {
    padding: 10px 20px;
   font-size: 16px;
   }

}

@media only screen and (min-width:992px) and (max-width:1024px) {

  .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 130px;
  }

}

@media only screen and (min-width:767px) and (max-width:991px) {
  .home-banner{
    padding-top:71px;
  }




}

@media only screen and (min-width:0px) and (max-width:991px) {
  .banner-txt-container{
    display: none;
  }
  .service-item{
    background: #fff;
  }

  .service-item img{
    width: 36px;
  }
  .service-item p{
    font-size: 14px;
  }
  .home-service-item{
    height: 100px;
  }

  .service-item {
    padding-top: 15px;
  }
  .home-b-content{
    padding-bottom: 100px;
  }


  .home-banner{
    padding-top:68px;
  }

  .home-service-content{
    padding: 0;
  }
  .swiper-pagination-bullet{
    width: 10px;
    height: 10px;
  }

  .swiper-pagination-fraction, .swiper-pagination-custom,
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 20px;

  }

}





</style>