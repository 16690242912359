<template>
<div class="tab-control">
<div v-for="(item, index) in titles" :key="index"
     class="tab-control-item" :class="{active: index === currentIndex}"
     @click="itemclick(index)">
 <span>{{item}}</span>
</div>
</div>
</template>

<script>
export default {
  name: "TabControl",
  props: {
    titles: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      currentIndex: 0
    }
  },
  methods: {
    itemclick(index) {
       this.currentIndex = index;//获取当前下标
       this.$emit('tabClick', index) //传递index给父组件
    }
  }
}
</script>

<style scoped>
.tab-control{
  display: flex;
  text-align: center;
}

.tab-control-item{
  flex:1;
  height: 88px;
  line-height: 88px;
  font-size: 16px;
  background: #F76F01;
  border-radius: 8px 8px 0px 0px;
  color: #fff;
  margin-right: 20px;
  cursor: pointer;
}

.tab-control-item span{
  padding: 5px;
}

.active{
  background: #fff;
}

.active span{
  color: #ff5777;
  color: #F76F01;
  font-weight: bold;
}

@media only screen and (min-width:0px) and (max-width:991px) {
  .tab-control-item{
    height: 56px;
    line-height: 56px;
  }

}


</style>
