<template>
  <Header/>
  <!-- :key="$route.fullPath" 解决url跳转了,数据没更新的问题 -->
 <keep-alive>
   <router-view :key="$route.fullPath" v-if="$route.meta.keepalive"/>
</keep-alive>
  <router-view :key="$route.fullPath" v-if="!$route.meta.keepalive"/>
  <Footer/>
</template>
<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: 'App',
  components: {
    Header,
    Footer
  },


}

</script>

<style>

@import "assets/css/layui.css";
@import "assets/css/main.css";

</style>
