import {request} from "@/network/request";

//index
export function  getIndexInfo() {
    return request({
        url: 'index'//请求地址
    })
}


//bottom
export function  getBottomInfo() {
    return request({
        url: 'contact-us'//请求地址
    })
}

//about
export function  getAboutInfo() {
    return request({
        url: 'introduction-of-our-company'//请求地址
    })
}


//donate
export function getDonate() {
    return request({
        url: 'donations'//请求地址
    })
}

//channel
export function getChannel() {
    return request({
        url: 'channel'//请求地址
    })
}


//contact
export function getContactInfo() {
    return request({
        url: 'contact-us'//请求地址
    })
}


//download table
export function getDownloadList(page) {
    return request({
        url: 'table-downloads',
        params:{
            page
        }
    })
}


//serve
export function getServeList() {
    return request({
        url: 'service-scopes'//请求地址
    })
}

// serveMore
export function getDetail(id){
    return request({
        url: '/service-scopes/'+ id,
        // params:{
        //     id  这个是?id=x 的格式
        // }
    })
}


//news-recommend
export function getRecommend(page){
    return request({
        url: '/news/1',
        params:{
            page
        }
    })
}

//news-review
export function getNewReview(page){
    return request({
        url: '/news/2',
        params:{
            page
        }
    })
}

//news-other
export function getOther(page){
    return request({
        url: '/news/3',
        params:{
            page
        }
    })
}

//newsDetail
export function getNewsDetail(id){
    return request({
        url: '/new/'+ id,
    })
}

//--------------- 机构刊物 有二级分类 --------------- //

//PDF-administration
export function getAdministrationList(id,page){
    return request({
        url: '/ins-publication/1/' + id,
        params:{
           page,
        }
    })
}


//PDF-tokwawan
export function getTokList(id,page){
    return request({
        url: '/ins-publication/2/' + id,
        params:{
             page
        }
    })
}

//PDF-cuiping
export function getCuList(id,page){
    return request({
        url: '/ins-publication/3/' + id,
        params:{
            page
        }
    })
}


//--------------- 机构刊物 无分类 --------------- //



//PDF-administration
export function getAdministrationListNo(page){
    return request({
        url: '/ins-publication/1',
        params:{
            page,
        }
    })
}


//PDF-tokwawan
export function getTokListNo(page){
    return request({
        url: '/ins-publication/2',
        params:{
            page
        }
    })
}

//PDF-cuiping
export function getCuListNo(page){
    return request({
        url: '/ins-publication/3',
        params:{
            page
        }
    })
}


