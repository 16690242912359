<template>
<div id="footer">
  <div class="footer-top">
      <div class="container">
          <div class="footer-top-s">
             <div class="footer-logo">
                <router-link to="/">
                  <img src="@/assets/img/logo@2x.png">
                </router-link>
             </div>
            <div class="footer-txt-s">
               <h3>{{setting.title}}</h3>
               <p>{{setting.info}}</p>
            </div>
          </div>
      </div>
  </div>
  <div class="footer-center">
    <div class="container">
      <div class="footer-center-c">
        <div class="footer-center-1" id="bottom-nav">
           <h3>關於我們</h3>
           <router-link to="/about">
             <i class="layui-icon layui-icon-ok"></i>關於我們
           </router-link>
          <router-link to="/elder">
            <i class="layui-icon layui-icon-ok"></i>服務範疇
          </router-link>
          <router-link to="/recommend">
            <i class="layui-icon layui-icon-ok"></i>最新消息
          </router-link>
          <router-link to="/administration">
            <i class="layui-icon layui-icon-ok"></i>機構刊物
          </router-link>
          <router-link to="/channel">
            <i class="layui-icon layui-icon-ok"></i>康齡頻道
          </router-link>
          <router-link to="/tableDownload" style="display: block">
            <i class="layui-icon layui-icon-ok"></i>表格下載
          </router-link>
        </div>
        <div class="footer-center-1" id="bottom-contact">
          <h3>聯絡我們</h3>
           <div>
             <i class="layui-icon layui-icon-ok"></i>地址： {{ address }}
           </div>
          <div>
            <i class="layui-icon layui-icon-ok"></i>電話： {{ tel }}
          </div>
          <div>
            <i class="layui-icon layui-icon-ok"></i>傳真： {{ fax }}
          </div>
          <div>
            <i class="layui-icon layui-icon-ok"></i>電郵： {{ email }}
          </div>
        </div>

        <div class="footer-center-1" v-if="Object.keys(friendly_links).length !== 0"  >
          <h3>友好連結</h3>
           <a :href="item.link" class="friends" v-for="item in friendly_links" :key="item" target="_blank">
            <span>
              <i class="layui-icon layui-icon-ok"></i>
            </span>
            <div>
              <p>{{item.title}}</p>
              <p>{{item.link}}</p>
            </div>
           </a>

        </div>
        <div class="bottom-donate">
          <div class="donate-content">
            <span>
              <img src="@/assets/img/icon_contribution.png">
            </span>
            <span>
              捐款
            </span>
          </div>
          <p>工聯會康齡服務社代表長者感謝您的愛心捐贈，一齊關愛長者，共同為長者福祉而努力。</p>
          <router-link to="/donate">
            現在捐款
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-bottom">
    <div class="container">
      <div class="footer-bottom-c">
        <div class="copyright">
        <p>{{ setting.copy_num }}</p>
      </div>
        <div class="facebook">
          <a :href="setting.facebook_link" target="_blank">
           <span>
              <img src="@/assets/img/icon_facebook.png">
            </span>
          <span>Facebook</span>
          </a>
        </div>
        </div>
    </div>
  </div>

</div>
</template>

<script>
import {getBottomInfo} from "@/network/home";
export default {
  name: "Footer",
  data() {
    return {
      friendly_links: [],
      setting:{},
      tel:{},
      fax:{},
      email:{},
      address:{},
    }
  },
  created() {
    this.getBottomInfo()
  },
  methods: {
    getBottomInfo() {
      getBottomInfo().then(res =>{//.then可以拿到数据
         //友好链接
         this.friendly_links = res.data.data.friendly_links;
         //底部文字
         this.setting = res.data.data.setting;
         //联系信息
         this.tel = res.data.data.tel;
         this.fax = res.data.data.fax;
         this.email = res.data.data.email;
         this.address = res.data.data.address;
       })
    },
  }
}
</script>

<style scoped>
.footer-top{
  padding: 15px 0;
  background-color: #F88F00;
  border-bottom: 1px solid #fff;
}
.footer-top-s{
  display: flex;
  align-items: center;
}
.footer-logo img{
  height: 72px;
  padding-right: 60px;
  border-right: 1px solid #E4D8C8;
}

.footer-txt-s{
  padding-left: 60px;
  color: #fff;
}
.footer-txt-s h3{
  font-weight: normal;
   padding-bottom: 10px;
}
.footer-txt-s p{
  color: #EBEBEB;
}
.footer-center{
  background-color: #F88F00;
  padding: 20px 0;
  color: #fff;
}

.footer-center-c{
  display: flex;
  justify-content: space-between;
}

.footer-center-1 h3{
  margin-bottom: 20px;
  font-weight: bold;
}

.footer-center-1 a{
 display: block;
  color: #fff;
  padding: 5px 0;
}

.footer-center-1 i{
  padding-right: 10px;
}

.bottom-donate{
   width: 300px;
  height: 280px;
  border-radius: 5px;
  background: #F70132;
  padding: 20px;
   color: #fff;
}


a.friends{
  display: flex;
  align-items: center;
}

.donate-content span{
  font-size: 24px;
  padding-right: 30px;
  font-weight: bold;
}
.bottom-donate p{
  padding: 15px 0;
  font-size: 16px;
  text-align: justify;
}

.bottom-donate a{
  display: block;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  color: #F70132;
  background-color: #fff;
  padding: 15px 0;
  border-radius: 4px;
}
.footer-bottom{
  background: #F76F01;
  padding: 20px 0;
}
.footer-bottom-c{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.copyright p, .facebook a{
  font-size: 13px;
  color: #fff;
}

.facebook a{
  display: flex;
  align-items:  center;
}

.facebook img{
  padding-right: 10px;
}

#bottom-contact div{
  padding: 10px 0;
}


@media only screen and (min-width:0px) and (max-width:991px) {
 #bottom-contact{
  display: none;
}
.footer-top{
  display: none;
}
  .footer-center{
    padding: 30px 0;
  }

}

@media only screen and (min-width:0px) and (max-width:767px) {
.footer-center-c{
   flex-wrap: wrap;
}

  .footer-center-1 h3{
    margin-bottom: 10px;
  }
 .bottom-donate{
   margin-top: 20px;
 }
.footer-bottom-c{
  flex-wrap: wrap;
}

.copyright{
  width: 100%;
  text-align: center;
}
  .facebook {
    width: 100%;
    padding-top: 15px;
  }

  .facebook a{
    justify-content: center;

  }

  #bottom-nav {
    width: 100%;
    margin-bottom: 10px;
  }

  #bottom-nav a{
    width: 50%;
    float: left;
  }
  .footer-center-1 a{
    padding: 5px 0;
   }

  .bottom-donate{
    width: 100%;
    height: inherit;
  }


}


</style>