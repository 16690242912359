<template>
<div class="news-list">
  <swiper
      :breakpoints='{
              320: {
                "slidesPerView": 1,
                "spaceBetween": 10
              },
              991: {
                "slidesPerView": 2,
                "spaceBetween": 20
              },
              1025: {
                "slidesPerView": 3,
                "spaceBetween": 20
              },
              }'

      :loop="false"
      :loopFillGroupWithBlank="true"
      :navigation="true"
      :modules="modules"
      class="mySwiper">

    <swiper-slide v-for="(item,index) in news" :key="index">
      <div class="home-news-item">
        <news-item  :newsItem="item"></news-item>
      </div>
    </swiper-slide>


  </swiper>

</div>
</template>

<script>
import NewsItem from "@/components/content/newsList/newsItem";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";

// Import Swiper styles
import 'swiper/swiper-bundle.css'

// import required modules
import { Navigation } from "swiper";


export default {
  name: "newsSwiper",
  components: {
    Swiper,
    SwiperSlide,
    NewsItem
  },
  data(){
    return{
      activeIndex: 1
    }

  },
  props: {
    news: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    tabClick(index) {
      // console.log(index)
      switch (index) {
        case 0:  //如果当前的index是0,默认展示pop
          this.currentType = 'pop'
          break
        case 1: //如果是1, 展示new
          this.currentType = 'new'
          break
        case 2:
          this.currentType = 'sell'
          break
      }
    },
 },
  setup() {
    return {
      modules: [Navigation],
    };
  },

}
</script>

<style>
.news-list{
  background: #F5F5F6;
  padding: 50px 30px;
  min-height: 600px;
}
.home-news-item{
  width: 100%;
}

.swiper-button-prev{
  left:0;
  z-index: 99;

}

.swiper-button-next{
  right: 0;
  z-index: 99;
}

.swiper-button-prev, .swiper-button-next{
  color: #fff;

  background:rgb(0, 0, 0, .6);
}

.swiper-button-prev:after, .swiper-button-next:after{
 font-size: 14px;
}

.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
  opacity:0;
}



@media only screen and (min-width:0px) and (max-width:991px) {


  .news-list{

    padding:20px;
    min-height:490px;
  }



}




</style>