<template>
<div class="home-s-txt">
  <div class="container">
    <div class="index-big-title">
      <h1>服務範疇</h1>
    </div>
    <div class="home-s-middle">
      <div class="home-service-left">
        <div class="common-title">
          <div class="common-title-top">
           <span>
             <i class="layui-icon layui-icon-rate-solid"></i>
           </span>
            <span class="common-line his-line"></span>
          </div>
          <h3 style="color:#F88F00;">歷史與宗旨</h3>
        </div>
        <div class="short-intro">
          <p>{{setting.text_one}}</p>
        </div>

        <div class="home-center-intro">
          <h1>
            <router-link to="/serviceMore/1">
              土瓜灣中心
              <span>
            <i class="layui-icon layui-icon-right"></i>
            </span>
            </router-link>

          </h1>
          <p>
            {{setting.text_two}}
          </p>
        </div>

        <div class="home-center-intro">
          <h1>
            <router-link to="/serviceMore/4">
              翠屏中心<span>
            <i class="layui-icon layui-icon-right"></i></span>
            </router-link>
          </h1>

          <p>
            {{setting.text_three}}
          </p>
        </div>

      </div>
      <div class="home-service-right">

        <div class="home-s-img-2">
          <img v-for="item in setting.images" :key="item" :src="item">

        </div>
      </div>
    </div>
  </div>

</div>
</template>

<script>
export default {
  name: "homeService",
  props: {
    setting: {
      type:Object, //返回对象
      default() {
        return {}
      }
    }
  }
}
</script>

<style scoped>
.home-s-txt{
  padding: 80px 0;
  background: #F6F5F5;
}

.index-big-title h1{
  text-align: center;
  font-weight: bolder;
  font-size: 70px;
  color: #000;
  margin-bottom: 50px;
}

.home-s-middle{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-service-left{
  width: 40%;
  padding-right: 40px;
}

.home-service-right{
  display: flex;
  width: 60%;
}

.home-s-img-2 img{
  width: 50%;
  padding: 10px 0 10px 20px;
}

.short-intro{
  margin:15px 0 30px 0;
}
.short-intro p{
  color: #332D30;
}

.home-center-intro{
 margin-bottom: 30px;
}

.home-center-intro h1{
  color: #F70132;
  font-size: 30px;
  display: flex;
  align-items: center;
  font-weight: bold;
  padding-bottom: 10px;
}

.home-center-intro h1 span{
  height: 36px;
  width: 36px;
  display: inline-block;
  border-radius: 100%;
  border: 1px solid #F70132;
  margin-left: 10px;
}

.home-center-intro h1 i{
  line-height: 36px;
  text-align: center;
  font-size: 18px;
  display: block;
}

.home-center-intro h1 a{
  color: #F70132;
}

.home-center-intro h4{
  font-size: 17px;
  font-weight: bold;
  padding-bottom: 10px;
}
.home-center-intro p{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
 -webkit-box-orient: vertical;
}

.home-center-intro h1:hover a{
     color: #F70132;
}


.home-center-intro h1:hover span{
  background: rgba(247, 1, 50, 0.4);
  border: 1px solid #f693a7;
}

.home-center-intro h1:hover span i{
  color: #fff;
}


@media only screen and (min-width:0px) and (max-width:1024px) {
.home-s-middle{
  flex-wrap: wrap;
}
  .home-service-left {
    width: 100%;
    padding-right: 0;
  }

  .home-service-right{
    width: 100%;
  }

  .home-s-txt{
    padding: 20px 0;
    background-image: none;
  }
  .home-center-intro h1{
   font-size: 20px;
  }

  .home-center-intro h1 span{
    height: 20px;
    width: 20px;

  }

  .home-center-intro h1 i{
    line-height: 20px;

    font-size: 14px;

  }

  .short-intro{
    margin: 10px 0 20px 0;
  }

  .home-center-intro{
    margin-bottom: 20px;
  }


  .index-big-title h1{
    font-size: 30px;
    margin-bottom: 10px;
  }


}

</style>